window.addEventListener("DOMContentLoaded", () => {
  const searchButton = document.getElementById("subsection-search-btn");
  const searchFormContainer = document.getElementById("search-form");
  if (searchButton === null || searchFormContainer === null) {
	  return;
  }
  const searchInput = searchFormContainer.querySelector("input[type='search']");

  function showHideSearch(e) {
    e.preventDefault();
    e.target.closest("a").classList.toggle("active");
    searchFormContainer.classList.toggle("hidden-form");

    if (e.target.closest("a").classList.contains("active")) {
        searchInput.focus();
    } else {
        searchInput.value = '';
    }

  }

  searchButton?.addEventListener("click", showHideSearch);
});


